
import { defineComponent, ref, computed, reactive, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { Plus, Edit, Delete, Check, Close } from '@element-plus/icons'
import { useRouter } from 'vue-router'
import useBaseCostReponsitories from '@/repositories/useBaseCostReponsitories'
import { IBaseCostGroup } from '@/interfaces/base-cost'
import { ElMessage } from 'element-plus'
import { formatDateTime } from '@/utils/dateTime'
import { Search as IconSearch } from '@element-plus/icons'
import { useNotification } from '@/composables'
import { ElNotification } from 'element-plus'

export default defineComponent({
  components: {
    sectionLayoutContent,
    Plus,
    Edit,
    Delete,
    Check,
    Close,
    IconSearch,
  },
  setup() {
    const breadcrumbs = ['Base Cost', '']
    const router = useRouter()
    const content = computed(() => {
      return { breadcrumbs }
    })
    const { success, error } = useNotification()
    const {
      getBaseCostGroups,
      postBaseCostGroup,
      patchBaseCostGroup,
      deleteBaseCostGroup,
      getListBaseCost,
      deleteBaseCost,
    } = useBaseCostReponsitories()

    onMounted(async () => {
      getListBaseCostGroups()
    })

    const getListBaseCostGroups = async () => {
      state.isLoading = true
      let resBaseCostGroups = await getBaseCostGroups(state.params)
      state.groups = resBaseCostGroups as IBaseCostGroup[]
      state.resBaseCosts = (await getListBaseCost({
        page: 1,
        limit: 100,
      })) as any[]
      state.baseCosts = [...state.resBaseCosts]
      state.isLoading = false
    }

    const formBaseCost = ref()

    const state = reactive({
      resBaseCosts: ref<any[]>([]),
      isLoading: false,
      isLoadingAction: false,
      params: {
        page: 1,
        limit: 10,
      },
      dialogVisible: false,
      ruleForm: {
        name: '',
        level: 1,
        id: '',
      } as IBaseCostGroup,
      rules: {
        name: [
          {
            required: true,
            message: 'Please input group name',
            trigger: 'blur',
          },
        ],
        level: [
          {
            required: true,
            message: 'Please select level',
            trigger: 'blur',
          },
        ],
      },
      groups: ref<IBaseCostGroup[]>([]),
      baseCosts: ref<any[]>([]),
      columns: ref([
        {
          title: 'Created',
          dataIndex: 'createdAt',
          minWidth: 20,
        },
        {
          title: 'Name',
          dataIndex: 'name',
          minWidth: 30,
        },
        {
          title: 'Standard',
          dataIndex: 'default',
          minWidth: 20,
          align: 'center',
        },
        // {
        //   title: "Status",
        //   dataIndex: "status",
        // },
        {
          title: 'Action',
          dataIndex: 'action',
          minWidth: 20,
          align: 'center',
        },
      ]),
      collapseGroups: ['1', '2', '3', '4', '5'],
      search: '',
    })

    const groupWithLevel = computed(() => {
      let result: any = {}

      state.groups.forEach(item => {
        if (!result[item.level]) {
          result[item.level] = []
        }
        result[item.level].push(item)
      })

      return result
    })

    const onShowEditGroup = (group: IBaseCostGroup) => {
      state.ruleForm = { ...group }
      state.dialogVisible = true
    }

    const onShowAddBaseCost = () => {
      let formData = {
        level: 1,
        name: '',
      }
      state.ruleForm = { ...formData }
      state.dialogVisible = true
    }

    const onSubmit = async () => {
      formBaseCost.value.validate(async (valid: any) => {
        if (valid) {
          let formData = { ...state.ruleForm }
          state.isLoadingAction = true
          if (formData.id) {
            // let id = formData.id as string;
            let res = await patchBaseCostGroup(formData)
            if (res.status === 200 || res.status === 201) {
              getListBaseCostGroups()
            } else {
              ElMessage.error(res.data.message)
            }
          } else {
            let res = await postBaseCostGroup(formData)
            if (res.status === 200 || res.status === 201) {
              getListBaseCostGroups()
            } else {
              ElMessage.error(res.data.message)
            }
          }
          state.dialogVisible = false
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }

    const onDeleteGroup = async (id: string) => {
      try {
        let res = await deleteBaseCostGroup(id)
        if (res.status === 200 || res.status === 201) {
          success('Delete success')
          getListBaseCostGroups()
        } else {
          ElMessage.error(res.data.message)
        }
      } catch (error) {
        console.log('Error submit!!')
      }
    }

    const onDeleteBaseCost = async (id: string) => {
      try {
        let res = await deleteBaseCost(id)
        if (res.status === 200 || res.status === 201) {
          success(res.data.message)
          getListBaseCostGroups()
        } else {
          ElNotification({
            title: 'Warning',
            message: `${res.data.message}`,
            type: 'warning',
          })
        }
      } catch (error) {
        console.log('error submit!!')
      }
    }

    const onSearchBaseCost = async (group: any) => {
      let baseCosts = [...state.resBaseCosts]
      state.baseCosts = baseCosts.filter((b: any) => {
        if (
          (b.group && b.group.id !== group.id) ||
          !group.search ||
          (b.group &&
            b.group.id === group.id &&
            b.name.toLowerCase().includes(group.search.toLowerCase()))
        ) {
          return true
        }
        return false
      })
    }

    return {
      content,
      state,
      formBaseCost,
      router,
      groupWithLevel,
      onSubmit,
      onShowEditGroup,
      onDeleteGroup,
      onShowAddBaseCost,
      onDeleteBaseCost,
      onSearchBaseCost,
      formatDateTime,
    }
  },
})
